.theme-body {
    margin: 0;
    padding: 0;
    background-color: #cccc !important;
}

.PhoneInputInput{
    padding: 0.5rem;
}


.choosed {
    background-color: #1976D2 !important;
    color: white;
}