@tailwind base;
@tailwind components;
@tailwind utilities;

.gallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .gallery-image {
    width: 200px;
    height: auto;
    cursor: pointer;
  }
  
  .popup {
    display: none;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .popup-image {
    display: block;
    width: 50%;
    height: auto;
  }
  
  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 30px;
    color: white;
    cursor: pointer;
  }
  
input,textarea, select {
  background-color: transparent;
}

:is(.dark input,textarea, select){
  color:white;
}

input,textarea, select{
  color:black;
}

:is(.dark #picker){
  background-color:white;
}

#picker {
  background-color: black;
}
input {
  border: 1px solid gray !important;
}

:is(.dark .MuiBox-root) {
  -webkit-box-shadow: 0px 0px 22px 0px rgba(255,255,255,1);
  -moz-box-shadow: 0px 0px 22px 0px rgba(255,255,255,1);
  box-shadow: 0px 0px 22px 0px rgba(255,255,255,1);
}
.MuiBox-root {
  -webkit-box-shadow: 0px 0px 22px 0px rgba(0,0,0,1);
  -moz-box-shadow: 0px 0px 22px 0px rgba(0,0,0,1);
  box-shadow: 0px 0px 22px 0px rgba(0,0,0,1);
}