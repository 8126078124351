.side-item{
    width: 100% !important;
}

#filter_ilce div div input  {
    outline: none !important;
    border: none !important;
}

#filter_il div div div input  {
    border: none !important;
    outline: none !important;
}

@media screen and (max-width:1034px) {
    .side-item{
        margin-top: 20px;
    }
    
}